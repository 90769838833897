



































import { Vue, Component, Watch } from "vue-property-decorator";
import Item from "./item.vue";
import { api_customer, faq } from "@/api";
import { OtherModule } from "@/store/other";
@Component({
  components: {
    Item,
  },
})
export default class extends Vue {
  page = 1;

  count = 0;

  list: faq[] = [];

  @Watch("page")
  async get_list() {
    if (this.list.length === this.count && this.list.length !== 0) {
      return this.$message.error("추가 내용이 없습니다.");
    }
    this._loading = true;
    const { results, count } = await api_customer
      .get_faq({
        page: this.page,
      })
      .finally(() => {
        this._loading = false;
      });
    this.list = [...this.list, ...results];
    this.count = count;
  }

  more() {
    this.page++;
  }

  created() {
    this.get_list();
  }

  get disabled(): boolean {
    return this.count <= this.list.length;
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
