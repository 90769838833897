





























import { Vue, Component, Prop } from "vue-property-decorator";
import { faq } from "@/api";
import Inner from "@/components/inner/index.vue";
import { OtherModule } from "@/store/other";
@Component({
  components: {
    Inner,
  },
})
export default class extends Vue {
  key = false;

  @Prop({ required: true })
  info!: faq;

  show() {
    this.key = !this.key;
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
